body {
  margin: 0;
  font-family: "Montserrat", "Segoe UI", Tahoma, sans-serif, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
}

/*
body {
  background-color: #efefef;
  color: #333;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}
*/
a {
  color: #4183c4;
}

#root {
  width: 100%;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p.code {
  font-size: 1.2em;
  text-align: center;
  color: #f8bc45;
}

header {
  /*display: flex;*/
  background: linear-gradient(130deg, #80a1d4 0, #9a7197 100%);
  overflow: hidden;
  font-weight: 600;
  color: white;
  padding-top: 5em;
  padding-bottom: 5em;
}

@media screen and (min-width: 800px) {
  header h1 {
    font-size: 4.6em;
  }

  header .code {
    font-size: 2.3em;
  }
}

.grid-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  /*grid-gap: 0 10px;
  grid-auto-rows: 10px;*/
  text-align: left;
  background-color: #182025;
}

.grid-list div {
  padding: 0 10px;
}

.grid-list ul {
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
}

.clearer {
  display: block;
  width: 100%;
}
