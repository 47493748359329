@import url(http://fonts.googleapis.com/css?family=Lato:300, 400, 700);
body {
  margin: 0;
  font-family: "Montserrat", "Segoe UI", Tahoma, sans-serif, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
}

/*
body {
  background-color: #efefef;
  color: #333;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}
*/
a {
  color: #4183c4;
}

#root {
  width: 100%;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p.code {
  font-size: 1.2em;
  text-align: center;
  color: #f8bc45;
}

header {
  /*display: flex;*/
  background: linear-gradient(130deg, #80a1d4 0, #9a7197 100%);
  overflow: hidden;
  font-weight: 600;
  color: white;
  padding-top: 5em;
  padding-bottom: 5em;
}

@media screen and (min-width: 800px) {
  header h1 {
    font-size: 4.6em;
  }

  header .code {
    font-size: 2.3em;
  }
}

.grid-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  /*grid-gap: 0 10px;
  grid-auto-rows: 10px;*/
  text-align: left;
  background-color: #182025;
}

.grid-list div {
  padding: 0 10px;
}

.grid-list ul {
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
}

.clearer {
  display: block;
  width: 100%;
}

.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.image-card {
  flex: 0 0 98%;
  padding: 1em;
  background: #eee;
  cursor: pointer;
  margin: 1%;
  order: 0;
}

.record-card {
  flex: 0 0 98%;
  margin: 1%;
  border: 1px solid #ddd;
  padding: 1em;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.record-card h3 {
  margin-bottom: 0 !important;
  padding-bottom: 0;
}

.record-card .date {
  color: #aaa;
}

.record-card p {
  margin-top: 0.5em;
  color: #888;
}

.record-card .record-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
  color: #888;
  padding-top: 0.5em;
  margin-top: 1em;
}

.image-card img {
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .image-card {
    flex: 1 0 48%;
  }

  .image-card-wide {
    flex: 0 0 98%;
    order: 1;
  }

  .image-card-medium {
    flex: 0 0 98%;
  }

  .record-card {
    flex: 0 0 48%;
  }
}

@media only screen and (min-width: 780px) {
  .image-card {
    flex: 0 0 31%;
  }

  .image-card-wide {
    flex: 0 0 64%;
    order: 0;
  }

  .image-card-medium {
    flex: 0 0 48%;
  }

  .record-card {
    flex: 0 0 23%;
  }
}

/*!
 * html5-device-mockups (https://github.com/pixelsign/html5-device-mockups)
 * Copyright 2013 - 2018 pixelsign
 * Licensed under MIT (https://github.com/pixelsign/html5-device-mockups/blob/master/LICENSE.txt)
 * Last Build: Thu Dec 20 2018 14:05:50
 */
.device-wrapper {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}

.device {
  position: relative;
  background-size: cover;
}
.device::after {
  position: absolute;
  background-size: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.device .screen {
  position: absolute;
  background-size: cover;
  pointer-events: auto;
}
.device .button {
  position: absolute;
  cursor: pointer;
}

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 198.89807163%;
  background-image: url(/static/media/portrait.2964dc03.png);
  z-index: auto;
  z-index: initial;
}
.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]
  .screen {
  top: 3.254847645%;
  left: 7.162534435%;
  width: 85.67493113%;
  height: 93.49030471%;
  border: 0px;
  z-index: -1;
}

.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 50.27700831%;
  background-image: url(/static/media/landscape.2d15fa03.png);
  z-index: auto;
  z-index: initial;
}
.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"]
  .screen {
  top: 7.162534435%;
  left: 3.254847645%;
  width: 93.49030471%;
  height: 85.67493113%;
  border: 0px;
  z-index: -1;
}

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 203.103448276%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="black"]
  .screen {
  top: 11.9977362762%;
  left: 6.2068965517%;
  width: 87.3563218391%;
  height: 76.0611205433%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="black"]::after {
  content: "";
  background-image: url(/static/media/portrait_black.4600c228.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="black"]
  .button {
  top: 89.5302773062%;
  left: 42.2988505747%;
  width: 15.7471264368%;
  height: 7.753254103%;
}

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 203.103448276%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="white"]
  .screen {
  top: 11.9977362762%;
  left: 6.2068965517%;
  width: 87.3563218391%;
  height: 76.0611205433%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="white"]::after {
  content: "";
  background-image: url(/static/media/portrait_white.acc043f6.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="white"]
  .button {
  top: 89.5302773062%;
  left: 42.2988505747%;
  width: 15.7471264368%;
  height: 7.753254103%;
}

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 203.103448276%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="gold"]
  .screen {
  top: 11.9977362762%;
  left: 6.2068965517%;
  width: 87.3563218391%;
  height: 76.0611205433%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="gold"]::after {
  content: "";
  background-image: url(/static/media/portrait_gold.ded1ae18.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="gold"]
  .button {
  top: 89.5302773062%;
  left: 42.2988505747%;
  width: 15.7471264368%;
  height: 7.753254103%;
}

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="pink"] {
  padding-bottom: 203.103448276%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="pink"]
  .screen {
  top: 11.9977362762%;
  left: 6.2068965517%;
  width: 87.3563218391%;
  height: 76.0611205433%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="pink"]::after {
  content: "";
  background-image: url(/static/media/portrait_pink.8a21fa53.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="pink"]
  .button {
  top: 89.5302773062%;
  left: 42.2988505747%;
  width: 15.7471264368%;
  height: 7.753254103%;
}

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="red"] {
  padding-bottom: 201.910828025%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="red"]
  .screen {
  top: 11.9873817035%;
  left: 6.6878980892%;
  width: 84.7133757962%;
  height: 75.5520504732%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="red"]::after {
  content: "";
  background-image: url(/static/media/portrait_red.7ea49bd2.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="red"]
  .button {
  top: 89.7476340694%;
  left: 42.3566878981%;
  width: 15.6050955414%;
  height: 7.7287066246%;
}

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 49.2359932088%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="black"]
  .screen {
  top: 6.4367816092%;
  left: 11.9977362762%;
  width: 76.0611205433%;
  height: 87.3563218391%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="black"]::after {
  content: "";
  background-image: url(/static/media/landscape_black.9c7220ad.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="black"]
  .button {
  top: 41.9540229885%;
  left: 89.5302773062%;
  width: 7.753254103%;
  height: 15.7471264368%;
}

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 49.2359932088%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="white"]
  .screen {
  top: 6.4367816092%;
  left: 11.9977362762%;
  width: 76.0611205433%;
  height: 87.3563218391%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="white"]::after {
  content: "";
  background-image: url(/static/media/landscape_white.b32c328f.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="white"]
  .button {
  top: 41.9540229885%;
  left: 89.5302773062%;
  width: 7.753254103%;
  height: 15.7471264368%;
}

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="gold"] {
  padding-bottom: 49.2359932088%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="gold"]
  .screen {
  top: 6.4367816092%;
  left: 11.9977362762%;
  width: 76.0611205433%;
  height: 87.3563218391%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="gold"]::after {
  content: "";
  background-image: url(/static/media/landscape_gold.57a33c6e.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="gold"]
  .button {
  top: 41.9540229885%;
  left: 89.5302773062%;
  width: 7.753254103%;
  height: 15.7471264368%;
}

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="pink"] {
  padding-bottom: 49.2359932088%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="pink"]
  .screen {
  top: 6.4367816092%;
  left: 11.9977362762%;
  width: 76.0611205433%;
  height: 87.3563218391%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="pink"]::after {
  content: "";
  background-image: url(/static/media/landscape_pink.fc45a628.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="pink"]
  .button {
  top: 41.9540229885%;
  left: 89.5302773062%;
  width: 7.753254103%;
  height: 15.7471264368%;
}

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="red"] {
  padding-bottom: 49.5268138801%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="red"]
  .screen {
  top: 8.5987261146%;
  left: 11.9873817035%;
  width: 75.5520504732%;
  height: 84.7133757962%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="red"]::after {
  content: "";
  background-image: url(/static/media/landscape_red.af7d18d2.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="red"]
  .button {
  top: 42.0382165605%;
  left: 89.7476340694%;
  width: 7.7287066246%;
  height: 15.6050955414%;
}

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 198.898071625%;
}
.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]
  .screen {
  top: 3.1855955679%;
  left: 6.8870523416%;
  width: 86.2258953168%;
  height: 93.6288088643%;
}
.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]::after {
  content: "";
  background-image: url(/static/media/portrait_black.2964dc03.png);
}
.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]
  .button {
  display: none;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 50.2770083102%;
}
.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"]
  .screen {
  top: 6.3360881543%;
  left: 3.4626038781%;
  width: 93.0747922438%;
  height: 87.3278236915%;
}
.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"]::after {
  content: "";
  background-image: url(/static/media/landscape_black.2d15fa03.png);
}
.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"]
  .button {
  display: none;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 141.392978482%;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="black"]
  .screen {
  top: 9.0909090909%;
  left: 6.4552661382%;
  width: 86.9762174405%;
  height: 82.0184221065%;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="black"]::after {
  content: "";
  background-image: url(/static/media/portrait_black.f8f3d7d7.png);
  z-index: -1;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="black"]
  .button {
  top: 93.5522627153%;
  left: 46.8289920725%;
  width: 6.2853907135%;
  height: 4.4453344013%;
}

.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 141.392978482%;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="white"]
  .screen {
  top: 9.0909090909%;
  left: 6.4552661382%;
  width: 86.9762174405%;
  height: 82.0184221065%;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="white"]::after {
  content: "";
  background-image: url(/static/media/portrait_white.58aa1f40.png);
  z-index: -1;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="white"]
  .button {
  top: 93.5522627153%;
  left: 46.8289920725%;
  width: 6.2853907135%;
  height: 4.4453344013%;
}

.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 141.392978482%;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="gold"]
  .screen {
  top: 9.0909090909%;
  left: 6.4552661382%;
  width: 86.9762174405%;
  height: 82.0184221065%;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="gold"]::after {
  content: "";
  background-image: url(/static/media/portrait_gold.7d2df871.png);
  z-index: -1;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="gold"]
  .button {
  top: 93.5522627153%;
  left: 46.8289920725%;
  width: 6.2853907135%;
  height: 4.4453344013%;
}

.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 70.7248698438%;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="black"]
  .screen {
  top: 6.5685164213%;
  left: 9.0909090909%;
  width: 82.0184221065%;
  height: 86.9762174405%;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="black"]::after {
  content: "";
  background-image: url(/static/media/landscape_black.85683619.png);
  z-index: -1;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="black"]
  .button {
  top: 46.885617214%;
  left: 93.5522627153%;
  width: 4.4453344013%;
  height: 6.2853907135%;
}

.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 70.7248698438%;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="white"]
  .screen {
  top: 6.5685164213%;
  left: 9.0909090909%;
  width: 82.0184221065%;
  height: 86.9762174405%;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="white"]::after {
  content: "";
  background-image: url(/static/media/landscape_white.85769ae7.png);
  z-index: -1;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="white"]
  .button {
  top: 46.885617214%;
  left: 93.5522627153%;
  width: 4.4453344013%;
  height: 6.2853907135%;
}

.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="gold"] {
  padding-bottom: 70.7248698438%;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="gold"]
  .screen {
  top: 6.5685164213%;
  left: 9.0909090909%;
  width: 82.0184221065%;
  height: 86.9762174405%;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="gold"]::after {
  content: "";
  background-image: url(/static/media/landscape_gold.9245ca60.png);
  z-index: -1;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="gold"]
  .button {
  top: 46.885617214%;
  left: 93.5522627153%;
  width: 4.4453344013%;
  height: 6.2853907135%;
}

/* General Demo Style */
/*
@font-face {
  font-family: "fontawesome";
  src: url("../fonts/fontawesome.eot");
  src: url("../fonts/fontawesome.eot?#iefix") format("embedded-opentype"),
    url("../fonts/fontawesome.svg#fontawesome") format("svg"),
    url("../fonts/fontawesome.woff") format("woff"),
    url("../fonts/fontawesome.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
*/
.showcase-container {
  font-size: 100%;
  padding: 0;
  margin: 0;
}

/* Reset */
*,
*:after,
*:before {
  box-sizing: border-box;
}

/* Clearfix hack by Nicolas Gallagher: http://nicolasgallagher.com/micro-clearfix-hack/ */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.showcase-container {
  font-family: "Lato", Calibri, Arial, sans-serif;
  color: #333;
  background: #f9f9f9;
  background: #ffffff;
  font-size: 0.9em;
  overflow-x: hidden;
}

.showcase-container a {
  color: #999;
  text-decoration: none;
}

.showcase-container a:hover {
  color: #000;
}

.showcase-container > header {
  padding: 1em 1.875em;
  margin-bottom: 2em;
  background: #f5f5f5;
  position: relative;
}

.inner {
  max-width: 65em;
  width: 100%;
  margin: 0 auto;
}

.showcase-container > header h1 {
  font-size: 2.125em;
  line-height: 1.3;
  text-align: left;
  font-weight: 400;
  float: left;
  width: 50%;
}

.showcase-container > header span {
  display: block;
  font-weight: 300;
  font-size: 75%;
  color: #c0c0c0;
  padding: 0 0 0.6em 0.1em;
}

.main > p {
  font-weight: 300;
  font-size: 1.1em;
  color: #999;
  text-align: center;
  padding: 2em;
}

/* Header Style */
.showcase-top {
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  position: relative;
  font-size: 0.75em;
  box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.2);
  line-height: 2.2;
}

.showcase-top a {
  padding: 0 1em;
  letter-spacing: 0.1em;
  color: #333;
  display: inline-block;
}

.showcase-top a:hover {
  background: rgba(255, 255, 255, 0.8);
  color: #000;
}

.showcase-top span.right {
  float: right;
}

.showcase-top span.right a {
  float: left;
  display: block;
}

/* Demo Buttons Style */
.header-links {
  float: right;
  padding-top: 1em;
}

.header-links a {
  display: inline-block;
  margin: 1em;
  line-height: 2;
  color: #666;
  font-weight: 700;
  border-bottom: 4px solid transparent;
}

.header-linksa:hover {
  color: #000;
  border-color: #000;
}

.header-links a.current-demo,
.header-links a.current-demo:hover {
  color: #aaa;
  border-color: #aaa;
}

.ac-wrapper {
  width: 100%;
  position: relative;
  perspective: 1000px;
  perspective-origin: 50% 25%;
}

.ac-wrapper h2 {
  top: 20%;
  width: 50%;
  position: absolute;
  font-weight: 300;
  font-size: 4em;
  text-align: right;
  padding: 0 180px 0 50px;
}

.ac-wrapper h2 span {
  display: block;
  font-size: 60%;
  color: #c0c0c0;
}

.ac-device {
  background: url(/static/media/iPhone.bb8e042d.png) no-repeat;
  width: 288px;
  height: 611px;
  margin: 0 auto;
  position: relative;
  transition: all 0.3s ease;
  transform-style: preserve-3d;
}

.ac-device div {
  height: 440px;
  width: 249px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 85px 0 0 20px;
}

.ac-device div img {
  display: block;
  position: absolute;
  max-width: 249px;
}

.ac-device h3 {
  position: absolute;
  font-size: 2.3em;
  left: 100%;
  width: 100%;
  top: 50%;
  margin-left: 30px;
  font-weight: 300;
  color: #888;
}

.ac-device nav {
  position: absolute;
  bottom: 1em;
  left: 100%;
  margin-left: 2em;
  width: 100%;
}

.ac-device nav span {
  display: block;
  font-size: 2.8em;
  font-weight: 300;
  cursor: pointer;
  color: #fff;
  background: #ddd;
  line-height: 0.9;
  padding: 0 0.08em;
  margin: 2px;
  float: left;
  width: 36px;
  text-align: center;
}

.ac-grid {
  position: absolute;
  width: 620px;
  left: 50%;
  margin-left: -310px;
  height: 100%;
  z-index: 1000;
  top: 0;
  opacity: 0;
  pointer-events: none;
  transform-style: preserve-3d;
  transition: all 0.3s ease;
  transform: translateZ(-350px);
}

.ac-grid a {
  width: 145px;
  display: block;
  position: relative;
  float: left;
  margin: 10px 5px;
  cursor: pointer;
}

.ac-grid a img {
  display: block;
  width: 100%;
}

.ac-grid a span {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  text-transform: uppercase;
  padding: 3em 1em 0;
  z-index: 100;
  color: #ddd;
  background: rgba(0, 0, 0, 0.4);
  font-weight: 700;
  opacity: 0;
  transform: translateY(-5px);
  transition: all 0.2s ease;
}

.ac-grid a:hover span {
  opacity: 1;
  transform: translateY(0);
}

.ac-wrapper.ac-gridview .ac-device {
  transform: translateZ(-350px);
  opacity: 0.6;
}

.ac-wrapper.ac-gridview .ac-grid {
  transform: translateZ(0px);
  opacity: 1;
  pointer-events: auto;
}

@media screen and (max-width: 63.875em) {
  .ac-wrapper {
    font-size: 60%;
    width: 100%;
    padding: 0 20px;
  }

  .ac-device {
    margin: 0;
    width: 100%;
  }

  .ac-device h3 {
    width: 50%;
    left: 290px;
  }

  .ac-wrapper h2 {
    left: 308px;
    padding: 0;
    text-align: left;
    margin-left: 30px;
  }

  .ac-device nav {
    left: 280px;
    font-size: 2em;
  }
}

@media screen and (max-width: 39.8125em) {
  .ac-grid {
    width: 90%;
    left: 5%;
    margin-left: 0;
    padding-top: 150px;
  }

  .ac-grid a {
    width: 22%;
  }
}

@media screen and (max-width: 35.6875em) {
  .ac-wrapper {
    padding: 0 20px 100px;
  }

  .ac-wrapper h2 {
    width: 100%;
    text-align: center;
    margin: 0 0 1em;
    top: 0;
    left: auto;
    position: relative;
  }

  .ac-device {
    margin: 0 auto;
    width: 288px;
  }

  .ac-device h3 {
    position: relive;
    margin: 0;
    left: auto;
    top: auto;
    width: 100%;
    padding-top: 100px;
    display: block;
    text-align: center;
  }

  .ac-device nav {
    position: relative;
    padding-top: 20px;
    text-align: center;
    bottom: auto;
    margin: 0;
    left: auto;
  }
}

.formContainer {
  background: linear-gradient(130deg, #80a1d4 0, #9a7197 100%);
  color: #fff;
  padding: 2em 0;
}

