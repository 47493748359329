.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.image-card {
  flex: 0 0 98%;
  padding: 1em;
  background: #eee;
  cursor: pointer;
  margin: 1%;
  order: 0;
}

.record-card {
  flex: 0 0 98%;
  margin: 1%;
  border: 1px solid #ddd;
  padding: 1em;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.record-card h3 {
  margin-bottom: 0 !important;
  padding-bottom: 0;
}

.record-card .date {
  color: #aaa;
}

.record-card p {
  margin-top: 0.5em;
  color: #888;
}

.record-card .record-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
  color: #888;
  padding-top: 0.5em;
  margin-top: 1em;
}

.image-card img {
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .image-card {
    flex: 1 0 48%;
  }

  .image-card-wide {
    flex: 0 0 98%;
    order: 1;
  }

  .image-card-medium {
    flex: 0 0 98%;
  }

  .record-card {
    flex: 0 0 48%;
  }
}

@media only screen and (min-width: 780px) {
  .image-card {
    flex: 0 0 31%;
  }

  .image-card-wide {
    flex: 0 0 64%;
    order: 0;
  }

  .image-card-medium {
    flex: 0 0 48%;
  }

  .record-card {
    flex: 0 0 23%;
  }
}
