/* General Demo Style */
@import url(http://fonts.googleapis.com/css?family=Lato:300, 400, 700);
/*
@font-face {
  font-family: "fontawesome";
  src: url("../fonts/fontawesome.eot");
  src: url("../fonts/fontawesome.eot?#iefix") format("embedded-opentype"),
    url("../fonts/fontawesome.svg#fontawesome") format("svg"),
    url("../fonts/fontawesome.woff") format("woff"),
    url("../fonts/fontawesome.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
*/
.showcase-container {
  font-size: 100%;
  padding: 0;
  margin: 0;
}

/* Reset */
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Clearfix hack by Nicolas Gallagher: http://nicolasgallagher.com/micro-clearfix-hack/ */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.showcase-container {
  font-family: "Lato", Calibri, Arial, sans-serif;
  color: #333;
  background: #f9f9f9;
  background: #ffffff;
  font-size: 0.9em;
  overflow-x: hidden;
}

.showcase-container a {
  color: #999;
  text-decoration: none;
}

.showcase-container a:hover {
  color: #000;
}

.showcase-container > header {
  padding: 1em 1.875em;
  margin-bottom: 2em;
  background: #f5f5f5;
  position: relative;
}

.inner {
  max-width: 65em;
  width: 100%;
  margin: 0 auto;
}

.showcase-container > header h1 {
  font-size: 2.125em;
  line-height: 1.3;
  text-align: left;
  font-weight: 400;
  float: left;
  width: 50%;
}

.showcase-container > header span {
  display: block;
  font-weight: 300;
  font-size: 75%;
  color: #c0c0c0;
  padding: 0 0 0.6em 0.1em;
}

.main > p {
  font-weight: 300;
  font-size: 1.1em;
  color: #999;
  text-align: center;
  padding: 2em;
}

/* Header Style */
.showcase-top {
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  position: relative;
  font-size: 0.75em;
  box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.2);
  line-height: 2.2;
}

.showcase-top a {
  padding: 0 1em;
  letter-spacing: 0.1em;
  color: #333;
  display: inline-block;
}

.showcase-top a:hover {
  background: rgba(255, 255, 255, 0.8);
  color: #000;
}

.showcase-top span.right {
  float: right;
}

.showcase-top span.right a {
  float: left;
  display: block;
}

/* Demo Buttons Style */
.header-links {
  float: right;
  padding-top: 1em;
}

.header-links a {
  display: inline-block;
  margin: 1em;
  line-height: 2;
  color: #666;
  font-weight: 700;
  border-bottom: 4px solid transparent;
}

.header-linksa:hover {
  color: #000;
  border-color: #000;
}

.header-links a.current-demo,
.header-links a.current-demo:hover {
  color: #aaa;
  border-color: #aaa;
}

.ac-wrapper {
  width: 100%;
  position: relative;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
  -webkit-perspective-origin: 50% 25%;
  -moz-perspective-origin: 50% 25%;
  perspective-origin: 50% 25%;
}

.ac-wrapper h2 {
  top: 20%;
  width: 50%;
  position: absolute;
  font-weight: 300;
  font-size: 4em;
  text-align: right;
  padding: 0 180px 0 50px;
}

.ac-wrapper h2 span {
  display: block;
  font-size: 60%;
  color: #c0c0c0;
}

.ac-device {
  background: url(./Showcase/inc/iPhone.png) no-repeat;
  width: 288px;
  height: 611px;
  margin: 0 auto;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.ac-device div {
  height: 440px;
  width: 249px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 85px 0 0 20px;
}

.ac-device div img {
  display: block;
  position: absolute;
  max-width: 249px;
}

.ac-device h3 {
  position: absolute;
  font-size: 2.3em;
  left: 100%;
  width: 100%;
  top: 50%;
  margin-left: 30px;
  font-weight: 300;
  color: #888;
}

.ac-device nav {
  position: absolute;
  bottom: 1em;
  left: 100%;
  margin-left: 2em;
  width: 100%;
}

.ac-device nav span {
  display: block;
  font-size: 2.8em;
  font-weight: 300;
  cursor: pointer;
  color: #fff;
  background: #ddd;
  line-height: 0.9;
  padding: 0 0.08em;
  margin: 2px;
  float: left;
  width: 36px;
  text-align: center;
}

.ac-grid {
  position: absolute;
  width: 620px;
  left: 50%;
  margin-left: -310px;
  height: 100%;
  z-index: 1000;
  top: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: translateZ(-350px);
  -moz-transform: translateZ(-350px);
  transform: translateZ(-350px);
}

.ac-grid a {
  width: 145px;
  display: block;
  position: relative;
  float: left;
  margin: 10px 5px;
  cursor: pointer;
}

.ac-grid a img {
  display: block;
  width: 100%;
}

.ac-grid a span {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  text-transform: uppercase;
  padding: 3em 1em 0;
  z-index: 100;
  color: #ddd;
  background: rgba(0, 0, 0, 0.4);
  font-weight: 700;
  opacity: 0;
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.ac-grid a:hover span {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

.ac-wrapper.ac-gridview .ac-device {
  -webkit-transform: translateZ(-350px);
  -moz-transform: translateZ(-350px);
  transform: translateZ(-350px);
  opacity: 0.6;
}

.ac-wrapper.ac-gridview .ac-grid {
  -webkit-transform: translateZ(0px);
  -moz-transform: translateZ(0px);
  transform: translateZ(0px);
  opacity: 1;
  pointer-events: auto;
}

@media screen and (max-width: 63.875em) {
  .ac-wrapper {
    font-size: 60%;
    width: 100%;
    padding: 0 20px;
  }

  .ac-device {
    margin: 0;
    width: 100%;
  }

  .ac-device h3 {
    width: 50%;
    left: 290px;
  }

  .ac-wrapper h2 {
    left: 308px;
    padding: 0;
    text-align: left;
    margin-left: 30px;
  }

  .ac-device nav {
    left: 280px;
    font-size: 2em;
  }
}

@media screen and (max-width: 39.8125em) {
  .ac-grid {
    width: 90%;
    left: 5%;
    margin-left: 0;
    padding-top: 150px;
  }

  .ac-grid a {
    width: 22%;
  }
}

@media screen and (max-width: 35.6875em) {
  .ac-wrapper {
    padding: 0 20px 100px;
  }

  .ac-wrapper h2 {
    width: 100%;
    text-align: center;
    margin: 0 0 1em;
    top: 0;
    left: auto;
    position: relative;
  }

  .ac-device {
    margin: 0 auto;
    width: 288px;
  }

  .ac-device h3 {
    position: relive;
    margin: 0;
    left: auto;
    top: auto;
    width: 100%;
    padding-top: 100px;
    display: block;
    text-align: center;
  }

  .ac-device nav {
    position: relative;
    padding-top: 20px;
    text-align: center;
    bottom: auto;
    margin: 0;
    left: auto;
  }
}
