/*!
 * html5-device-mockups (https://github.com/pixelsign/html5-device-mockups)
 * Copyright 2013 - 2018 pixelsign
 * Licensed under MIT (https://github.com/pixelsign/html5-device-mockups/blob/master/LICENSE.txt)
 * Last Build: Thu Dec 20 2018 14:05:50
 */
.device-wrapper {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}

.device {
  position: relative;
  background-size: cover;
}
.device::after {
  position: absolute;
  background-size: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.device .screen {
  position: absolute;
  background-size: cover;
  pointer-events: auto;
}
.device .button {
  position: absolute;
  cursor: pointer;
}

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 198.89807163%;
  background-image: url(./device-mockups/iPhoneX/portrait.png);
  z-index: initial;
}
.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]
  .screen {
  top: 3.254847645%;
  left: 7.162534435%;
  width: 85.67493113%;
  height: 93.49030471%;
  border: 0px;
  z-index: -1;
}

.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 50.27700831%;
  background-image: url(./device-mockups/iPhoneX/landscape.png);
  z-index: initial;
}
.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"]
  .screen {
  top: 7.162534435%;
  left: 3.254847645%;
  width: 93.49030471%;
  height: 85.67493113%;
  border: 0px;
  z-index: -1;
}

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 203.103448276%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="black"]
  .screen {
  top: 11.9977362762%;
  left: 6.2068965517%;
  width: 87.3563218391%;
  height: 76.0611205433%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="black"]::after {
  content: "";
  background-image: url(./device-mockups/iPhone7/portrait_black.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="black"]
  .button {
  top: 89.5302773062%;
  left: 42.2988505747%;
  width: 15.7471264368%;
  height: 7.753254103%;
}

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 203.103448276%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="white"]
  .screen {
  top: 11.9977362762%;
  left: 6.2068965517%;
  width: 87.3563218391%;
  height: 76.0611205433%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="white"]::after {
  content: "";
  background-image: url(./device-mockups/iPhone7/portrait_white.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="white"]
  .button {
  top: 89.5302773062%;
  left: 42.2988505747%;
  width: 15.7471264368%;
  height: 7.753254103%;
}

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 203.103448276%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="gold"]
  .screen {
  top: 11.9977362762%;
  left: 6.2068965517%;
  width: 87.3563218391%;
  height: 76.0611205433%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="gold"]::after {
  content: "";
  background-image: url(./device-mockups/iPhone7/portrait_gold.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="gold"]
  .button {
  top: 89.5302773062%;
  left: 42.2988505747%;
  width: 15.7471264368%;
  height: 7.753254103%;
}

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="pink"] {
  padding-bottom: 203.103448276%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="pink"]
  .screen {
  top: 11.9977362762%;
  left: 6.2068965517%;
  width: 87.3563218391%;
  height: 76.0611205433%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="pink"]::after {
  content: "";
  background-image: url(./device-mockups/iPhone7/portrait_pink.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="pink"]
  .button {
  top: 89.5302773062%;
  left: 42.2988505747%;
  width: 15.7471264368%;
  height: 7.753254103%;
}

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="red"] {
  padding-bottom: 201.910828025%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="red"]
  .screen {
  top: 11.9873817035%;
  left: 6.6878980892%;
  width: 84.7133757962%;
  height: 75.5520504732%;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="red"]::after {
  content: "";
  background-image: url(./device-mockups/iPhone7/portrait_red.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="portrait"][data-color="red"]
  .button {
  top: 89.7476340694%;
  left: 42.3566878981%;
  width: 15.6050955414%;
  height: 7.7287066246%;
}

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 49.2359932088%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="black"]
  .screen {
  top: 6.4367816092%;
  left: 11.9977362762%;
  width: 76.0611205433%;
  height: 87.3563218391%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="black"]::after {
  content: "";
  background-image: url(./device-mockups/iPhone7/landscape_black.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="black"]
  .button {
  top: 41.9540229885%;
  left: 89.5302773062%;
  width: 7.753254103%;
  height: 15.7471264368%;
}

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 49.2359932088%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="white"]
  .screen {
  top: 6.4367816092%;
  left: 11.9977362762%;
  width: 76.0611205433%;
  height: 87.3563218391%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="white"]::after {
  content: "";
  background-image: url(./device-mockups/iPhone7/landscape_white.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="white"]
  .button {
  top: 41.9540229885%;
  left: 89.5302773062%;
  width: 7.753254103%;
  height: 15.7471264368%;
}

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="gold"] {
  padding-bottom: 49.2359932088%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="gold"]
  .screen {
  top: 6.4367816092%;
  left: 11.9977362762%;
  width: 76.0611205433%;
  height: 87.3563218391%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="gold"]::after {
  content: "";
  background-image: url(./device-mockups/iPhone7/landscape_gold.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="gold"]
  .button {
  top: 41.9540229885%;
  left: 89.5302773062%;
  width: 7.753254103%;
  height: 15.7471264368%;
}

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="pink"] {
  padding-bottom: 49.2359932088%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="pink"]
  .screen {
  top: 6.4367816092%;
  left: 11.9977362762%;
  width: 76.0611205433%;
  height: 87.3563218391%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="pink"]::after {
  content: "";
  background-image: url(./device-mockups/iPhone7/landscape_pink.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="pink"]
  .button {
  top: 41.9540229885%;
  left: 89.5302773062%;
  width: 7.753254103%;
  height: 15.7471264368%;
}

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="red"] {
  padding-bottom: 49.5268138801%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="red"]
  .screen {
  top: 8.5987261146%;
  left: 11.9873817035%;
  width: 75.5520504732%;
  height: 84.7133757962%;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="red"]::after {
  content: "";
  background-image: url(./device-mockups/iPhone7/landscape_red.png);
  z-index: -1;
}
.device[data-device="iPhone7"][data-orientation="landscape"][data-color="red"]
  .button {
  top: 42.0382165605%;
  left: 89.7476340694%;
  width: 7.7287066246%;
  height: 15.6050955414%;
}

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 198.898071625%;
}
.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]
  .screen {
  top: 3.1855955679%;
  left: 6.8870523416%;
  width: 86.2258953168%;
  height: 93.6288088643%;
}
.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]::after {
  content: "";
  background-image: url(./device-mockups/iPhoneX/portrait_black.png);
}
.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]
  .button {
  display: none;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 50.2770083102%;
}
.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"]
  .screen {
  top: 6.3360881543%;
  left: 3.4626038781%;
  width: 93.0747922438%;
  height: 87.3278236915%;
}
.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"]::after {
  content: "";
  background-image: url(./device-mockups/iPhoneX/landscape_black.png);
}
.device[data-device="iPhoneX"][data-orientation="landscape"][data-color="black"]
  .button {
  display: none;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 141.392978482%;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="black"]
  .screen {
  top: 9.0909090909%;
  left: 6.4552661382%;
  width: 86.9762174405%;
  height: 82.0184221065%;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="black"]::after {
  content: "";
  background-image: url(./device-mockups/iPadAir2/portrait_black.png);
  z-index: -1;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="black"]
  .button {
  top: 93.5522627153%;
  left: 46.8289920725%;
  width: 6.2853907135%;
  height: 4.4453344013%;
}

.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 141.392978482%;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="white"]
  .screen {
  top: 9.0909090909%;
  left: 6.4552661382%;
  width: 86.9762174405%;
  height: 82.0184221065%;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="white"]::after {
  content: "";
  background-image: url(./device-mockups/iPadAir2/portrait_white.png);
  z-index: -1;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="white"]
  .button {
  top: 93.5522627153%;
  left: 46.8289920725%;
  width: 6.2853907135%;
  height: 4.4453344013%;
}

.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 141.392978482%;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="gold"]
  .screen {
  top: 9.0909090909%;
  left: 6.4552661382%;
  width: 86.9762174405%;
  height: 82.0184221065%;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="gold"]::after {
  content: "";
  background-image: url(./device-mockups/iPadAir2/portrait_gold.png);
  z-index: -1;
}
.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="gold"]
  .button {
  top: 93.5522627153%;
  left: 46.8289920725%;
  width: 6.2853907135%;
  height: 4.4453344013%;
}

.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 70.7248698438%;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="black"]
  .screen {
  top: 6.5685164213%;
  left: 9.0909090909%;
  width: 82.0184221065%;
  height: 86.9762174405%;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="black"]::after {
  content: "";
  background-image: url(./device-mockups/iPadAir2/landscape_black.png);
  z-index: -1;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="black"]
  .button {
  top: 46.885617214%;
  left: 93.5522627153%;
  width: 4.4453344013%;
  height: 6.2853907135%;
}

.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 70.7248698438%;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="white"]
  .screen {
  top: 6.5685164213%;
  left: 9.0909090909%;
  width: 82.0184221065%;
  height: 86.9762174405%;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="white"]::after {
  content: "";
  background-image: url(./device-mockups/iPadAir2/landscape_white.png);
  z-index: -1;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="white"]
  .button {
  top: 46.885617214%;
  left: 93.5522627153%;
  width: 4.4453344013%;
  height: 6.2853907135%;
}

.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="gold"] {
  padding-bottom: 70.7248698438%;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="gold"]
  .screen {
  top: 6.5685164213%;
  left: 9.0909090909%;
  width: 82.0184221065%;
  height: 86.9762174405%;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="gold"]::after {
  content: "";
  background-image: url(./device-mockups/iPadAir2/landscape_gold.png);
  z-index: -1;
}
.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="gold"]
  .button {
  top: 46.885617214%;
  left: 93.5522627153%;
  width: 4.4453344013%;
  height: 6.2853907135%;
}
